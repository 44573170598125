import Vue from 'vue'
import App from './App.vue'
import { tryLoadAndStartRecorder } from '@alwaysmeticulous/recorder-loader'

async function startApp() {
      // Start the Meticulous recorder before you initialise your app.
      // Note: all errors are caught and logged, so no need to surround with try/catch
      await tryLoadAndStartRecorder({
        projectId: 'KzgBPntieeZT4sA1nwxoa3sjEXoG68hbwjjrpsIL',
      });


    // Initalise app after the Meticulous recorder is ready, e.g.
    new Vue({
      render: h => h(App)
    }).$mount("#app");
}
startApp();

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
