<template>
  <div class="calc-container">
    <div class="calc-main">
      <h1>Hifz Planner</h1>
      <p>Calculate your estimated Hifz end date</p>
      <hr>

      <div class="alert" v-if="errorMessage">
        {{errorMessage}}
      </div>

      <form 
        v-if="calculated === false"
        v-on:submit.prevent="calc"  
      >
        <div class="input-container">
            <label for="mushaf-type">Which type of Mus'haf do you use?</label>
            <select name="mushaf_type" id="mushaf_type" v-model="mushaf_type">
              <option value="-1" disabled>Please select</option>
              <option value="1">13 line</option>
              <option value="2">15 line</option>
            </select>
        </div>

        <div class="input-container">
          <label for="mem-from">I am memorising from:</label>
          <select name="" id="mem_from" v-model="mem_from">
            <option  value='-1' disabled>Please select</option>
            <option  value='1'>Al-Baqarah</option>
            <option  value='2'>An-Nas</option>
          </select>
        </div>

        <div class="input-container">
          <label for="page_num">Which page of the Mus'haf are you currently on?</label>
          <input type="number" id="page_num" min="0" max="850" v-model="page_num" />
        </div>

        <div class="input-container" v-if="parseInt(mem_from) === 2">
          <label for="page_mem_1">How many pages of your Surah have you memorised?</label>
          <input type="number" id="page_mem_1" min="0" max="70" v-model="page_mem_1" />
        </div>
        <div class="input-container" v-if="parseInt(mem_from) === 2">
          <label for="page_mem_2">How many pages of your Surah do you have left?</label>
          <input type="number" id="page_mem_2" min="0" max="70" v-model="page_mem_2" />
        </div>

        <div class="input-container">
          <label for="calc_type">Which calculator type would you like to use?</label>
          <select name="calc_type" id="calc_type" v-model="calc_type">
            <option  value="-1" disabled>Please select</option>
            <option  value='1'>Daily</option>
            <option  value='2'>Weekly</option>
          </select>
        </div>

        <hr>
        <p>How many pages & lines do you memorise per day/week?</p>

        <div class="input-container">
          <label>Number of pages?</label>
          <input type="number" id="page_amt_1" min="0" max="604" v-model="page_amt_1" />
        </div>
        <div class="input-container">
          <label>Number of lines?</label>
          <input type="number" id="page_amt_2" min="0" max="15" v-model="page_amt_2" />
        </div>

        <hr>
        <input type="submit" class="button" value="Calculate">
      </form>

      <!-- user result -->
      <div class="result" v-if="calculated === true && errorMessage === null">
        <h2>Calculated Result:</h2>
        <p>Your estimated end date is {{result}}</p>
        <button type="submit" class="button" @click="calculated = false">Re-Calculate</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CalculateDate.vue',
  props: {
    msg: String
  },
  data: function(){
    return {
      mem_from: -1,
      mushaf_type: -1,
      page_num: null,
      page_mem_1: null,
      page_mem_2: null,
      calc_type: -1,
      page_amt_1: 0,
      page_amt_2: 0,
      errorMessage: null,
      calculated: false,
      result: null,
    }
  },
methods: {
  validateInput() {
    // Validation rules for each input field
    const validationRules = {
      mem_from: {
        required: true,
      },
      page_num: {
        required: true,
        min: 0,
        max: 850,
      },
      calc_type: {
        required: true,
      },
      page_amt_1: {
        required: true,
        min: 0,
        max: 70,
      },
      page_amt_2: {
        required: true,
        min: 0,
        max: 70,
      },
      page_mem_1: {
        required: this.mem_from === 2,
        min: 0,
        max: 70,
      },
      page_mem_2: {
        required: this.mem_from === 2,
        min: 0,
        max: 70,
      },
    };

    // Check if both page_amt_1 and page_amt_2 are null
    if (this.page_amt_1 === null && this.page_amt_2 === null) {
      this.errorMessage = "Please add the amount of Quran you memorise per day/week.";
      return false; // Validation failed
    }

    // Check each input field against its validation rules
    for (const field in validationRules) {
      const rules = validationRules[field];

      if (rules.required && this[field] === null) {
        this.errorMessage = `Please enter ${field.replace('_', ' ')}.`;
        return false; // Validation failed
      }

      if (rules.min !== undefined && this[field] < rules.min) {
        this.errorMessage = `${field.replace('_', ' ')} should be at least ${rules.min}.`;
        return false; // Validation failed
      }

      if (rules.max !== undefined && this[field] > rules.max) {
        this.errorMessage = `${field.replace('_', ' ')} should be at most ${rules.max}.`;
        return false; // Validation failed
      }
    }

    // All validation checks passed
    this.errorMessage = null;
    return true; // Validation passed
  },
  calc() {
        //If 13 line mus'haf
        if (parseInt(this.mushaf_type) === 1){

        // If from Nas
        if (parseInt(this.mem_from) === 2){


        // Variables for our math.
        let today = new Date();
        let pages = 0;
        const page_on = parseInt(this.page_num)
        const page_surah = parseInt(this.page_mem_1)
        const page_surah_left = parseInt(this.page_mem_2)

        // calc for pages per day.
        if(parseInt(this.calc_type) === 1){
          let aa = parseInt(this.page_amt_2) / 13
          pages = parseInt(this.page_amt_1) + aa
          const days_left_days  = ( page_on - page_surah + page_surah_left ) / pages;
          this.result =  new Date(today.getTime() + parseInt(days_left_days)*24*60*60*1000);
        }
        
        // calc for pages per week
        if(parseInt(this.calc_type) === 2){
          let a2 = parseInt(this.page_amt_1)
          let b2 = parseInt(this.page_amt_2)
          pages = (a2 + (b2 / 13 )) / 7
          const days_left_week = ( page_on - page_surah + page_surah_left ) / pages;
          this.result =  new Date(today.getTime() + days_left_week*24*60*60*1000);
        }


        this.result = this.result.getDate() + '/' + ( this.result.getMonth() + 1 )  + '/' + this.result.getFullYear()
        this.calculated = true;
         
      }
// If user memorising from 'Al-Baqarah' we set Surah values to 0, otherwise they will be null.
			if (parseInt(this.mem_from) === 1) {
				this.page_mem_1 = 0;
				this.page_mem_2 = 0;

				// Variables for our math.
				let today = new Date();
				let pages = 0;
				const page_on = parseInt(this.page_num)

				// calc for pages per day.
				if (parseInt(this.calc_type) === 1) {
					let aa = parseInt(this.page_amt_2) / 13
					pages = parseInt(this.page_amt_1) + aa
					const days_left_days = (848 - page_on) / pages;
					this.result = new Date(today.getTime() + parseInt(days_left_days) * 24 * 60 * 60 * 1000);
				}

				// calc for pages per week
				if (parseInt(this.calc_type) === 2) {
					let a2 = parseInt(this.page_amt_1)
					let b2 = parseInt(this.page_amt_2)
					pages = (a2 + (b2 / 13)) / 7
					const days_left_week = (848 - page_on) / pages;
					this.result = new Date(today.getTime() + days_left_week * 24 * 60 * 60 * 1000);
				}
			this.result = this.result.getDate() + '/' + (this.result.getMonth() + 1) + '/' + this.result.getFullYear()
			this.calculated = true;
		}
	}
            //If 15 line mus'haf
            if (parseInt(this.mushaf_type) === 2){

            // If from Nas
            if (parseInt(this.mem_from) === 2){


            // Variables for our math.
            let today = new Date();
            let pages = 0;
            const page_on = parseInt(this.page_num)
            const page_surah = parseInt(this.page_mem_1)
            const page_surah_left = parseInt(this.page_mem_2)

            // calc for pages per day.
            if(parseInt(this.calc_type) === 1){
              let aa = parseInt(this.page_amt_2) / 15
              pages = parseInt(this.page_amt_1) + aa
              const days_left_days  = ( page_on - page_surah + page_surah_left ) / pages;
              this.result =  new Date(today.getTime() + parseInt(days_left_days)*24*60*60*1000);
            }

            // calc for pages per week
            if(parseInt(this.calc_type) === 2){
              let a2 = parseInt(this.page_amt_1)
              let b2 = parseInt(this.page_amt_2)
              pages = (a2 + (b2 / 15 )) / 7
              const days_left_week = ( page_on - page_surah + page_surah_left ) / pages;
              this.result =  new Date(today.getTime() + days_left_week*24*60*60*1000);
            }


            this.result = this.result.getDate() + '/' + ( this.result.getMonth() + 1 )  + '/' + this.result.getFullYear()
            this.calculated = true;

          }
    // If user memorising from 'Al-Baqarah' we set Surah values to 0, otherwise they will be null.
            if (parseInt(this.mem_from) === 1) {
                this.page_mem_1 = 0;
                this.page_mem_2 = 0;

                // Variables for our math.
                let today = new Date();
                let pages = 0;
                const page_on = parseInt(this.page_num)

                // calc for pages per day.
                if (parseInt(this.calc_type) === 1) {
                    let aa = parseInt(this.page_amt_2) / 15
                    pages = parseInt(this.page_amt_1) + aa
                    const days_left_days = (604 - page_on) / pages;
                    this.result = new Date(today.getTime() + parseInt(days_left_days) * 24 * 60 * 60 * 1000);
                }

                // calc for pages per week
                if (parseInt(this.calc_type) === 2) {
                    let a2 = parseInt(this.page_amt_1)
                    let b2 = parseInt(this.page_amt_2)
                    pages = (a2 + (b2 / 15)) / 7
                    const days_left_week = (604 - page_on) / pages;
                    this.result = new Date(today.getTime() + days_left_week * 24 * 60 * 60 * 1000);
                }
            this.result = this.result.getDate() + '/' + (this.result.getMonth() + 1) + '/' + this.result.getFullYear()
            this.calculated = true;
        }
    }
    },

    test (){
      let aa = parseInt(this.page_amt_1)
      let bb = parseInt(this.page_amt_2)
      let calc = (aa + (bb / 15 )) / 7
      console.log(calc)
    }

    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}

.calc-container {
  display: block;
  max-width: 400px;
  margin: 0 auto;
  background: #fff;
  padding: 20px;
  border-radius: 6px;
}

.alert {
  display: block;
  width: 100%;
  line-height: 50px;
  height: 50px;
  border-radius: 3px;
  font-size: 15px;
  color: #fff;
  background: red;
  margin: 10px 0;
}

.calc-container .button {
  display: block;
  height: 50px;
  line-height: 50px;
  border-radius: 4px;
  text-decoration: none;
  border: none;
  width: 320px;
  margin: 0 auto;
  opacity: 0.9;
  cursor: pointer;
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.5);
  background: #3ed071;
  font-size: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  max-width: 100%;
  transition: all 0.3s;
}
.calc-container .button:hover {
  opacity: 1;
  transition: all 0.3s;
}

hr {
  margin: 0 0 20px 0;
}

.input-container {
  display: block;
  padding: 0;
  width: 100%;
  margin: 0 0 20px 0;
}
.input-container label {
  display: block;
  text-align: left;
  width: 100%;
  font-size: 16px;
  margin: 0 0 10px 0;
}
.input-container input,
.input-container select {
  width: 100%;
  box-sizing: border-box;
  height: 30px;
  padding: 0 10px;
  line-height: 30px;
  border-radius: 2px;
  background: #f1f1f1;
  border: none;
}

label {
  font-size: 20px;
  font-weight: 600px;
}


</style>
