<template>
  <div id="app">
    <header>
      <h1>Welcome to Hifz Planner</h1>
    </header>
    <main>
      <Calc />
    </main>
    <footer>
      <p>&copy; 2023 Hifz Planner</p>
    </footer>
  </div>
</template>

<script>
import Calc from './components/CalculateDate.vue'

export default {
  name: 'App',
  components: {
    Calc
  }
}
</script>

<style>
body {
  margin: 0;
  padding: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f0f0f0;
}

#app {
  text-align: center;
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%; /* Add this to take up the full width */
  overflow-x: hidden; /* Add this to hide horizontal overflow if necessary */
}

header {
  background: #0078d4; /* A blue header background */
  color: #fff;
  padding: 20px;
  width: 100%;
}

header h1 {
  margin: 0;
  font-size: 24px;
}

main {
  background: #fff; /* White background for the main content */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 600px;
  margin: auto;
  flex-grow: 1; /* Allow the main content to grow and take up available space */
}

footer {
  background: #333; /* A dark footer background */
  color: #fff;
  padding: 0px;
  width: 100%;
  text-align: center;
}

@media screen and (max-width: 600px) {
  main {
    width: 90%; /* Adjust the width for smaller screens */
    padding: 10px; /* Increase padding for smaller screens */
  }
}
</style>
